export const baseUrl = "iris-ibmm-afg.gsf.amazon.dev/";

export const emptyAuthDetails = {
    username: "",
    email: ""
};

export const shipmentSummariesAPIRange = {
    min: -14,
    max: 14
};

export const defaultResultsPerPage = 10;

export const resultsPerPageOptions = [10, 25, 50, "All"];

export const ticketLinkBoxCharacterLimit = 100;

export const basicTempZones = ["AMBIENT", "CHILLED", "FROZEN"];

export const detailedTempZones = ["AMBIENT", "CHILLED", "FROZEN", "PRODUCE", "PROTEIN"];

export const shipmentQuantityKeys = {
    vendorConfirmedUnits: "quantityConfirmed",
    transConfirmedUnits: "manifestQuantity",
    vendorReceivedUnits: "quantityReceived",
    transReceivedUnits: "wmsQuantityReceivedStow",
    vendorTotalConfirmedUnits: "totalQuantityConfirmed",
    transTotalConfirmedUnits: "totalManifestQuantity",
    vendorTotalReceivedUnits: "totalQuantityReceived",
    transTotalReceivedUnits: "totalQuantityReceivedWmsStow",
}

export const slaShipmentProgressThreshold = 97;

export const loadIdDisplayLength = 15;

export const unscheduledVendorName = 'PO Pending Schedule';
export const notYetManifestedName = 'Not Yet Manifested';