/**
 * A modal that allows user to reconcile a dock received shipment.
 */

import React, { useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import sentryFetch from "@amzn/sentry-fetch";

import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Heading from "@amzn/meridian/heading"
import Icon from "@amzn/meridian/icon"
import Table, { TableCell, TableRow } from "@amzn/meridian/table"
import Text from "@amzn/meridian/text"
import Textarea from "@amzn/meridian/textarea"
import Select, { SelectOption } from "@amzn/meridian/select"
import Modal, { ModalFooter } from "@amzn/meridian/modal"
import alertTokens from "@amzn/meridian-tokens/base/icon/alert"

import useWarehouseData from "../../hooks/use-warehouse-data";
import dockReceivedPropType from "../../prop-types/dock-received"
import { convertUnix } from "../../utilities/convert-unix"
import { getWarehouseAttributes } from "../../utilities/get-warehouse-attributes";
import { ticketLinkBoxCharacterLimit } from "../Constants";
import { API } from "aws-amplify";
import { reconcileShipment } from "../../graphql/mutations";
import useAuth from "../../hooks/use-auth";

import { shipmentQuantityKeys } from "../Constants";
import {getReconcileLink} from "../../utilities/links";

const {
  vendorConfirmedUnits,
  vendorReceivedUnits,
  vendorTotalConfirmedUnits,
  vendorTotalReceivedUnits,
  transConfirmedUnits,
  transReceivedUnits,
  transTotalConfirmedUnits,
  transTotalReceivedUnits
} = shipmentQuantityKeys;

const ReconcileModal = props => {
  const { dockReceived, warehouseId } = props
  const userData = useAuth();
  const [open, setOpen] = useState(false);
  const [showTicketBox, setShowTicketBox] = useState(false);

  const { region_id, time_zone, wms } = getWarehouseAttributes(
    { warehouseId: warehouseId, warehouseParams: useWarehouseData() }
  );

  const orderId = dockReceived.orderId;
  const recordType = dockReceived.recordType;

  const updateReconciliationDetails = useCallback(async (newReconciliationDetails) => {
    await API.graphql({
      query: reconcileShipment,
      variables: { orderId: orderId, recordType: recordType, reconciliationDetails: JSON.stringify(newReconciliationDetails) }
    })
  }, [orderId, recordType]);

  const onClickButton = useCallback(() => setOpen(true), []);
  const onCloseButton = () => {
    setOpen(false);
  };


  const reconcileLink = getReconcileLink(region_id, wms, dockReceived.loadType, dockReceived);

  // Set the percentage that will trigger checks before closure
  const receivedThresholdPercentage = 1;

  // Bool for if percentage received is below the threshold above
  const belowThreshold = dockReceived.receivedUnits / dockReceived.confirmedUnits < receivedThresholdPercentage;

  const [disabled, setDisabled] = useState(belowThreshold);
  const [reconcileReason, setReconcileReason] = useState("");
  const [detailedReason, setDetailedReason] = useState("");

  const reconcileReasons = [
    "Vendor Shortage",
    "Rejection - Out of temp chilled/frozen items *Provide tt link required*",
    "Rejections - Safety Issues *Provide tt link required*",
    // "Other Reasons - *With detailed reasoning required*"
  ];

  // set the number of caution tokens at top of warning
  const cautionTokenCount = 12;


  // Enforcing our form requirements for reasons
  useEffect(() => {
    // show ticket box if rejections are selected
    setShowTicketBox(reconcileReason.includes("Provide tt link required"));
    if (belowThreshold && reconcileReason === "") {
      setDisabled(true);
    }
    else if (belowThreshold && reconcileReason.includes("Provide tt link required")) {
      setDisabled(!["sim.amazon.com", "t.corp.amazon.com"].some(substring => detailedReason.includes(substring)))
    }
    else {
      setDisabled(false);
    }
  }, [setDisabled, reconcileReason, detailedReason, belowThreshold]);


  const summary = dockReceived.poItemSummary ? JSON.parse(dockReceived.poItemSummary) : { summaryByTempZone: {} };

  const onConfirm = async () => {
    let stage = 'prod';
    if (window.location.hostname.includes('beta')) {
      stage = 'beta';
    } else if (window.location.hostname.includes('dev-dsk')) {
      stage = 'dev';
    }
    const iris_api_stage = stage === 'prod' ? 'prod' : 'gamma'
    const storedUuid = sessionStorage.getItem('uuid');
    if (!storedUuid) {
      // Generate a UUID if not found in session storage
      const generatedUuid = crypto.randomUUID();
      sessionStorage.setItem('uuid', generatedUuid);

    }
    const reconciliationDetails = {
      receiveProgress: summary.summaryByTempZone,
      reconcileTime: Date.now(),
      reconcileReason: reconcileReason,
      reconcileDetails: detailedReason,
      user: userData.username,
    }
    updateReconciliationDetails(reconciliationDetails)
    let body = {
      alias: userData.username || 'unknown',
      iam_auth_caller_arn: null,
      iam_auth_caller: null,
      application: 'Marauders Map v2',
      aws_region: 'us-east-1',
      metric_name: `Page View (Reconcile)`,
      metric_value: reconcileReason,
      session: sessionStorage.getItem('uuid'),
      stage: stage
    };
    sentryFetch(`https://${iris_api_stage}.api.iris.gsf.amazon.dev/common/iris_tool_usage_tracker`,
      {
        method: 'POST',
        mode: "cors",
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(body)
      })
      .then((response) => response.json())

    window.open(reconcileLink)
    onCloseButton()
  }

  const newTypedValue = useCallback(value => {
    if (value.length <= ticketLinkBoxCharacterLimit) {
      setDetailedReason(value)
    } else {
      setDetailedReason(value.slice(0, -1))
    }
  }, []);

  return (
    <>
      <Button
        size="small"
        minWidth="8%"
        onClick={onClickButton}
        data-testid="modalButton"
      >
        Reconcile
      </Button>
      <Modal
        title="Reconcile Shipment"
        open={open}
        scrollContainer="viewport"
        describedById="reconcile-modal-description"
        onClose={onCloseButton}
        closeLabel="Close"
      >
        <Row>
          <Column>
            <Row><Heading level={5}>{"Load Id:"}</Heading><Text>{dockReceived.loadId}</Text></Row>
            <Row><Heading level={5}>{"Site Id:"}</Heading><Text>{dockReceived.warehouseId}</Text></Row>
            <Row><Heading level={5}>{"Source:"}</Heading><Text>{dockReceived.source}</Text></Row>
            <Row><Heading level={5}>{"Dock Receive:"}</Heading><Text>{convertUnix(dockReceived.lastDockReceiveTime, time_zone)}</Text></Row>
            {belowThreshold &&
              <Row backgroundColor={"secondary"}>
                <Column>
                  <Text alignment={"center"}>
                    {[...Array(cautionTokenCount).keys()].map(tokenCount => <Icon tokens={alertTokens} color={"red"} />)}
                  </Text>
                  <Row><Text color={"secondary"}>{`Receive progress is below ${Math.floor(receivedThresholdPercentage * 100)}%. Please follow these steps:`}</Text></Row>
                  <Row>
                    <Text tag="ol" color={"secondary"}>
                      <li>Consider a dock walk to ensure no pending units are left behind.</li>
                      <li>Make sure no appointments are scheduled to arrive at the site at a later point in time.</li>
                      <li>You MUST provide a reason from the dropdown and if possible provide an explanation.</li>
                    </Text>
                  </Row>
                </Column>
              </Row>
            }
          </Column>
          <Column>
            <Row>
              <Table headerRows={1} showDividers={true}>
                <TableRow>
                  <TableCell header={true}><Heading level={5}>Temp Zone</Heading></TableCell>
                  <TableCell header={true}><Heading level={5}>Expected Units</Heading></TableCell>
                  <TableCell header={true}><Heading level={5}>NYR Units</Heading></TableCell>
                  <TableCell header={true}><Heading level={5}>Receive Progress</Heading></TableCell>
                </TableRow>
                {Object.entries(summary.summaryByTempZone).map(([tempZone, tempZoneValues]) =>
                  <TableRow>
                    <TableCell><Text>{tempZone}</Text></TableCell>
                    <TableCell>
                      <Text>
                        {dockReceived.loadType === "Vendor" ?
                          tempZoneValues[vendorConfirmedUnits]
                          // TODO: Let's simplify this after a couple of weeks after all trans have manifests
                          : tempZoneValues[transConfirmedUnits] ?
                            tempZoneValues[transConfirmedUnits] :
                            tempZoneValues.wmsQuantityReceived
                        }
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text>
                        {dockReceived.loadType === "Vendor" ?
                          tempZoneValues[vendorConfirmedUnits] - tempZoneValues[vendorReceivedUnits]
                          // TODO: Let's simplify this after a couple of weeks once we have all trans manifests
                          : (tempZoneValues[transConfirmedUnits] ? tempZoneValues[transConfirmedUnits] : tempZoneValues.wmsQuantityReceived) - tempZoneValues[transReceivedUnits]
                        }
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text>
                        {dockReceived.loadType === "Vendor" ?
                          `${Math.floor(tempZoneValues[vendorReceivedUnits] / tempZoneValues[vendorConfirmedUnits] * 10000) / 100}%`
                          // TODO: Let's simplify this after a couple of weeks once we have trans manifests
                          : `${Math.floor(tempZoneValues[transReceivedUnits] / (tempZoneValues[transConfirmedUnits] ? tempZoneValues[transConfirmedUnits] : tempZoneValues.wmsQuantityReceived) * 10000) / 100}%`
                        }
                      </Text>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell><Heading level={6}>Total</Heading></TableCell>
                  <TableCell>
                    <Heading level={6}>
                      {dockReceived.loadType === "Vendor" ?
                        summary.totalQuantityConfirmed
                        : Object.entries(summary.summaryByTempZone).map(([tempZone, tempZoneValues]) => tempZoneValues[transConfirmedUnits] ? tempZoneValues[transConfirmedUnits] : tempZoneValues.wmsQuantityReceived).reduce((partialSum, a) => partialSum + a, 0)
                      }
                    </Heading>
                  </TableCell>
                  <TableCell>
                    <Heading level={6}>
                      {dockReceived.loadType === "Vendor" ?
                        summary[vendorTotalConfirmedUnits] - summary[vendorTotalReceivedUnits]
                        : (summary[transTotalConfirmedUnits] ? summary[transTotalConfirmedUnits] : summary.totalQuantityReceivedWms) - summary[transTotalReceivedUnits]
                      }
                    </Heading>
                  </TableCell>
                  <TableCell>
                    <Heading level={6}>
                      {dockReceived.loadType === "Vendor" ?
                        `${Math.floor(summary[vendorTotalReceivedUnits] / summary[vendorTotalConfirmedUnits] * 10000) / 100}%`
                        : `${Math.floor(summary[transTotalReceivedUnits] / (summary[transTotalConfirmedUnits] ? summary[transTotalConfirmedUnits] : summary.totalQuantityReceivedWms) * 10000) / 100}%`
                      }
                    </Heading>
                  </TableCell>
                </TableRow>
              </Table>
            </Row>
            {belowThreshold &&
              <Row>
                <Select
                  label="Reason"
                  value={reconcileReason}
                  onChange={setReconcileReason}
                  placeholder="Enter reconciliation reason..."
                  width={300}
                >
                  {reconcileReasons.map(recReason => <SelectOption value={recReason} label={recReason} />)}
                </Select>
              </Row>
            }
            {showTicketBox &&
              <Row>
                <Textarea
                  value={detailedReason}
                  onChange={newTypedValue}
                  placeholder="Enter Ticket"
                  label="SIM/Trouble Ticket Link"
                  width={390}
                />
              </Row>
            }
          </Column>
        </Row>
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" onClick={onCloseButton}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={onConfirm}
              disabled={disabled}
            >
              Reconcile
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  )
}

ReconcileModal.propTypes = {
  dockReceived: dockReceivedPropType.isRequired,
  warehouseId: PropTypes.string.isRequired,
}


export default ReconcileModal
