interface TransshipmentLinks {
    AFTLite: string;
    PRIMS: string;
}

interface RegionTransshipmentMap {
    [key: number]: TransshipmentLinks;
}

export const getTransshipmentLink = (regionId: number, wms: string, loadId: string): string => {
    const transshipmentLinkMap: RegionTransshipmentMap = {
        1: {
            AFTLite: `https://aftlite-portal.amazon.com/receive_inventory/show?transferId=${loadId}`,
            PRIMS: `https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound-manifest-view-v2/${loadId}`
        },
        2: {
            AFTLite: `https://aftlite-portal-eu.amazon.com/receive_inventory/show?transferId=${loadId}`,
            PRIMS: `https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound-manifest-view-v2/${loadId}`
        },
        3: {
            AFTLite: `https://aftlite-portal-nrt.amazon.co.jp/receive_inventory/show?transferId=${loadId}`,
            PRIMS: `https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound-manifest-view-v2/${loadId}`
        }
    };

    return transshipmentLinkMap[regionId][wms];
};

interface VendorLinks {
    AFTLite: string;
    PRIMS: string;
}

interface RegionVendorMap {
    [key: number]: VendorLinks;
}

export const getVendorLink = (regionId: number, wms: string, loadId: string): string => {
    const vendorLinkMap: RegionVendorMap = {
        1: {
            AFTLite: `https://procurementportal-na.corp.amazon.com/bp/po?poId=${loadId}&tabId=summary`,
            PRIMS: `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${loadId}`
        },
        2: {
            AFTLite: `https://procurementportal-eu.corp.amazon.com/bp/po?poId=${loadId}&tabId=summary`,
            PRIMS: `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${loadId}`
        },
        3: {
            AFTLite: `https://procurementportal-fe.corp.amazon.com/bp/po?poId=${loadId}&tabId=summary`,
            PRIMS: `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${loadId}`
        }
    };

    return vendorLinkMap[regionId][wms];
};

interface DockReceiveLinks {
    Vendor: string;
    Transship: string;
}

interface WMSLinks {
    AFTLite: DockReceiveLinks;
    PRIMS: DockReceiveLinks;
}

interface RegionDockReceiveMap {
    [key: number]: WMSLinks;
}

export const getDockReceiveURL = (regionId: number, wms: string, loadType: string, loadId: string, storeId: string): string => {
    const dockReceiveURLMap: RegionDockReceiveMap = {
        1: {
            AFTLite: {
                Vendor: `https://aftlite-portal.amazon.com/dock_receive/receive?receivable_order_id=${loadId}`,
                Transship: `https://aftlite-portal.amazon.com/dock_receive/receive?receivable_order_id=${loadId}`
            },
            PRIMS: {
                Vendor: `https://ui.us-west-2.prod.associatetools.ihm.amazon.dev/remote/ihm-associate-tools-base/index.html?auth=midway&domain=prod&storeId=${storeId}`,
                Transship: `https://ui.us-west-2.prod.associatetools.ihm.amazon.dev/remote/ihm-associate-tools-base/index.html?auth=midway&domain=prod&storeId=${storeId}`
            }
        },
        2: {
            AFTLite: {
                Vendor: `https://aftlite-portal-eu.amazon.com/dock_receive/receive?receivable_order_id=${loadId}`,
                Transship: `https://aftlite-portal-eu.amazon.com/dock_receive/receive?receivable_order_id=${loadId}`
            },
            PRIMS: {
                Vendor: `https://ui.us-west-2.prod.associatetools.ihm.amazon.dev/remote/ihm-associate-tools-base/index.html?auth=midway&domain=prod&storeId=${storeId}`,
                Transship: `https://ui.us-west-2.prod.associatetools.ihm.amazon.dev/remote/ihm-associate-tools-base/index.html?auth=midway&domain=prod&storeId=${storeId}`
            }
        },
        3: {
            AFTLite: {
                Vendor: `https://aftlite-portal-nrt.amazon.com/dock_receive/receive?receivable_order_id=${loadId}`,
                Transship: `https://aftlite-portal-nrt.amazon.com/dock_receive/receive?receivable_order_id=${loadId}`
            },
            PRIMS: {
                Vendor: `https://ui.us-west-2.prod.associatetools.ihm.amazon.dev/remote/ihm-associate-tools-base/index.html?auth=midway&domain=prod&storeId=${storeId}`,
                Transship: `https://ui.us-west-2.prod.associatetools.ihm.amazon.dev/remote/ihm-associate-tools-base/index.html?auth=midway&domain=prod&storeId=${storeId}`
            }
        },
    };

    return dockReceiveURLMap[regionId][wms][loadType];
};

interface RegionProcurementMap {
    [key: number]: string
}

export const getProcurementURL = (regionId: number, loadId: string): string => {
    const procurementURLMap: RegionProcurementMap = {
        1: `https://procurementportal-na.corp.amazon.com/bp/po?poId=${loadId}&tabId=summary`,
        2: `https://procurementportal-eu.corp.amazon.com/bp/po?poId=${loadId}&tabId=summary`,
        3: `https://procurementportal-nrt.corp.amazon.com/bp/po?poId=${loadId}&tabId=summary`,
    };

    return procurementURLMap[regionId];
};

interface ReconcileLinks {
    Vendor: string;
    Transship: string;
}

interface WMSReconcileLinks {
    AFTLite: ReconcileLinks;
    PRIMS: ReconcileLinks;
}

interface RegionReconcileMap {
    [key: number]: WMSReconcileLinks;
}

interface DockReceivedData {
    loadId: string;
    transferId: string;
}

export const getReconcileLink = (
    regionId: number,
    wms: string,
    loadType: string,
    dockReceived: DockReceivedData
): string => {
    const reconcileLinkMap: RegionReconcileMap = {
        1: {
            AFTLite: {
                Vendor: `https://aftlite-portal.amazon.com/dock_receive/reconcile_shorts_overages?po=${dockReceived.loadId}`,
                Transship: `https://aftlite-portal.amazon.com/receive_inventory/show?transferId=${dockReceived.transferId}`
            },
            PRIMS: {
                Vendor: `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${dockReceived.loadId}`,
                Transship: `https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound-manifest-view-v2/${dockReceived.loadId}`
            }
        },
        2: {
            AFTLite: {
                Vendor: `https://aftlite-portal-eu.amazon.com/dock_receive/reconcile_shorts_overages?po=${dockReceived.loadId}`,
                Transship: `https://aftlite-portal-eu.amazon.com/receive_inventory/show?transferId=${dockReceived.transferId}`
            },
            PRIMS: {
                Vendor: `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${dockReceived.loadId}`,
                Transship: `https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound-manifest-view-v2/${dockReceived.loadId}`
            }
        },
        3: {
            AFTLite: {
                Vendor: `https://aftlite-portal-nrt.amazon.co.jp/dock_receive/reconcile_shorts_overages?po=${dockReceived.loadId}`,
                Transship: `https://aftlite-portal-nrt.amazon.co.jp/receive_inventory/show?transferId=${dockReceived.transferId}`
            },
            PRIMS: {
                Vendor: `https://dashboard.prod.whitetree.ihm.a2z.com/IhmVendorPODashboard/index.html#/vendor-pos-detail/${dockReceived.loadId}`,
                Transship: `https://dashboard.prod.whitetree.ihm.a2z.com/IhmTransshipDashboards/index.html#/inbound-manifest-view-v2/${dockReceived.loadId}`
            }
        }
    };

    return reconcileLinkMap[regionId][wms][loadType];
};

