import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Column from "@amzn/meridian/column";
import Tab, { TabGroup } from "@amzn/meridian/tab";
import Badge from "@amzn/meridian/badge";
import Tag from "@amzn/meridian/tag"

// API
import {
  getShipmentSummaries,
  getIOL,
  getAFTLiteIOL,
  getOCRVendors,
  getInstockRiskData,
  getInstockVBIData,
  getInstockUpdatedDate,
  getSCIPLatestForecast,
  getSCIPForecasts,
  getTSIForecasts,
  getFABForecasts,
  getIOLSnapshot,
  getShipmentSearchItems,
  getSingleShipmentSummary,
  getPalletId,
  getAsinsFromBarcode
} from "../graphql/queries";

// Helpers
import { calculateCurrentNYR } from "../utilities/calculate-current-nyr";
import { getStartOfDay } from "../utilities/get-start-of-day";
import { groupIOL } from "../utilities/group-iol";
import { groupInstock } from "../utilities/group-instock";
import { parseShipments } from "../utilities/parse-shipments";
import useWarehouseData from "../hooks/use-warehouse-data";
import { getWarehouseAttributes } from "../utilities/get-warehouse-attributes";
import { getPaginatedAPICall } from "../utilities/get-paginated-api-call";

// Tabs
import IncomingShipments from "../tabs/incoming-shipments";
import DockReceived from "../tabs/dock-received";
import IOL from "../tabs/iol";
import Instock from "../tabs/instock";

// Constants
import {shipmentSummariesAPIRange, slaShipmentProgressThreshold} from "../components/Constants";
import {MainAPI} from "../utilities/mainAPI";
import useTabActive from "../hooks/useTabActive";
import {ShipmentSearch} from "./shipment-search";
import {API} from "aws-amplify";
import {groupSearchedShipments} from "../utilities/group-searched-shipments";


const BuildingTabs = props => {
  const { warehouseId } = props;
  const navigate = useNavigate();
  const params = useParams();
  const [currentTab, setCurrentTab] = useState(params.selectedTab ? params.selectedTab : "");
  const [renderedTab, setRenderedTab] = useState(<></>);
  const [notifications, setNotifications] = useState({"pastSLA": [], "nearSLA": []});
  const [shipmentSummaries, setShipmentSummaries] = useState();
  const [incomingIncomingShipments, setIncomingIncomingShipments] = useState();
  const [incomingDockReceived, setIncomingDockReceived] = useState();
  const [incomingIOL, setIncomingIOL] = useState();
  const [instockData, setInstockData] = useState();
  const [tsiForecasts, setTSIForecasts] = useState();
  const [ocrVendors, setOcrVendors] = useState();
  const [currentNYR, setCurrentNyr] = useState();
  const [iolLastRefreshTime, setIOLLastRefreshTime] = useState()
  const [lastRefreshTime, setLastRefreshTime] = useState();
  const [dcEscalations, setDCEscalations] = useState([]);
  const tabActive = useTabActive();
  const [lostFocusTimestamp, setLostFocusTimestamp] = useState(Date.now());
  const [updatedInstock, setUpdatedInstock] = useState();
  const [instockVbi, setInstockVbi] = useState();
  const [vbiDate, setVbiDate] = useState();
  const [latestVbi, setLatestVbi] = useState(0);
  const [oosAsinCount, setOosAsinCount] = useState();
  const [oosRiskAsinCount, setOosRiskAsinCount] = useState();
  const [snapshotLocalInstock, setSnapshotLocalInstock] = useState("-");
  const [vbiCtm, setVbiCtm] = useState(0);
  const [scipForecasts, setScipForecasts] = useState();
  const [noosCount, setNoosCount] = useState(0);
  const { time_zone, region_id, wms, marketplace_id } = getWarehouseAttributes({ warehouseId: warehouseId, warehouseParams: useWarehouseData() });
  const [fabForecasts, setFabForecasts] = useState();
  const [searchResults, setSearchResults] = useState(undefined);
  const [searchInput, setSearchInput] = useState();
  const [searchLoading, setSearchLoading] = useState(false);

  const countNotifications = (incomingData) => {
    let notificationCount = {"pastSLA": [], "nearSLA": []};
    for (const index in incomingData) {
      if (incomingData[index].progress >= slaShipmentProgressThreshold) {
        continue
      }
      if (((incomingData[index].lastDockReceiveTime) + (incomingData[index].sla ? incomingData[index].sla : 24) * 60 * 60 * 1000)
          - Date.now() <= 0)  {
        notificationCount.pastSLA.push(incomingData[index])
      }
      else if (((incomingData[index].lastDockReceiveTime) + (incomingData[index].sla ? incomingData[index].sla : 24) * 60 * 60 * 1000)
          - Date.now() <= 2 * 60 * 60 * 1000)  {
        notificationCount.nearSLA.push(incomingData[index])
      }
    }
    return notificationCount;
  };

  const getWarehouseShipmentSummaries = useCallback(async (warehouse, timeZone) => {
    // If you're changing this make sure it batches the same api call in network-screen
    const params = {warehouse: {
        warehouseId: warehouse,
        minAppointmentTime: getStartOfDay(timeZone, shipmentSummariesAPIRange.min),
        maxAppointmentTime: getStartOfDay(timeZone, shipmentSummariesAPIRange.max)
      }};
    const data = await getPaginatedAPICall(params, getShipmentSummaries, "getShipmentSummaries");
    if (data) {
      setShipmentSummaries(data);
    }
  }, [setShipmentSummaries]);

  const getInstockData = useCallback(async (warehouse, timeZone) => {
    const params1 = {warehouse: {orderId: "ALL_NA", warehouse_asin: warehouse}};
    const dataDate = await getPaginatedAPICall(params1, getInstockUpdatedDate, "getInstockUpdatedDate");
    if (dataDate) {
      const recordUpdatedTime = (dataDate[0].recordUpdatedTime)
      const params = {warehouse: {warehouseId: warehouse, recordUpdatedTime: recordUpdatedTime}};
      const data = await getPaginatedAPICall(params, getInstockRiskData, "getInstockRisk");
      if (data && incomingDockReceived && recordUpdatedTime) {
        const maxWeight = Math.max(...data.map(item => item.total_weight));
        const idTimeMapWIthTempZone = new Map(incomingDockReceived.map(obj => [`${obj.orderId}+${obj.tempZone}`, [obj.suppression]]));
        // Store mappings of Shipment IDs with temp zones which are not suppresed
        const falseFlagMappings = {};
        for (let [key, [flag]] of idTimeMapWIthTempZone.entries()) {
          const [id, zone] = key.split('+');

          if (flag === false) {
            if (!falseFlagMappings[id]) {
              falseFlagMappings[id] = [];
            }
            falseFlagMappings[id].push(zone);
          }
        }
        setInstockData(groupInstock(data, falseFlagMappings, maxWeight));
        const datesWithData = data.map(obj => ({ ...obj, date: new Date(obj.snapshot_time_local) }));
        const latestDateWithData = datesWithData.reduce((maxDateObj, currentObj) => {
          return currentObj.date > maxDateObj.date ? currentObj : maxDateObj;
        }, datesWithData[0]);
        const latestSnapshotDate = latestDateWithData.snapshot_time_local;
        setSnapshotLocalInstock(latestSnapshotDate);
      }
    }
  }, [setInstockData, setSnapshotLocalInstock, incomingDockReceived]);

  const getInstockVbi = useCallback(async (warehouse, timeZone) => {
    const params = {warehouse: {warehouseId: warehouse}};
    const data = await getPaginatedAPICall(params, getInstockVBIData, "getInstockVBI");
    if (data){
      const maxUpdatedTime = Math.max(...data.map(item => item.record_updated_datetime));
      const recordsWithMaxUpdatedTime = data.filter(item =>item.record_updated_datetime === maxUpdatedTime);
      const selectedKeys = recordsWithMaxUpdatedTime.map(({ combined_datetime, vbi_weight_pcnt }) => ({ "snapshot_hour": combined_datetime.split(" ")[1].slice(0, -5).toString(), "vbi_weight_pcnt":  vbi_weight_pcnt*100 }))
      const datesWithData = data.map(obj => ({ ...obj, date: new Date(obj.combined_datetime) }));
      const latestDateWithData = datesWithData.reduce((maxDateObj, currentObj) => {
        return currentObj.date > maxDateObj.date ? currentObj : maxDateObj;
      }, datesWithData[0]); // Initialize with the first object
      const latestSnapshotDate = latestDateWithData.snapshot_day.split(" ")[0];
      setVbiDate(latestSnapshotDate);
      const latestvbi = ((latestDateWithData.vbi_weight_pcnt)*100).toFixed(2);
      setLatestVbi(latestvbi);
      setInstockVbi(selectedKeys);
    }

  }, [setInstockVbi, setLatestVbi, setVbiDate]);

  const getIOLData = useCallback(async (warehouse, timeZone, wms, regionId) => {
    if(wms==='AFTLite'){

      const params = {warehouse: {warehouseId: warehouse, dwellTimeThreshold: Date.now()}};
      const data = await getPaginatedAPICall(params, getAFTLiteIOL, "getAFTLiteIOL");
      if (data) {
        // add snapshot time filter
        const maxUpdatedTime = Math.max(...data.map(item => item.snapshotTime));
        // Filter the array to get records with the highest updated time
        const recordsWithMaxUpdatedTime = data.filter(item => item.snapshotTime === maxUpdatedTime
            // transferTote Filter
            && (item.locationType !== 'TransferTote'
                || (item.locationType === 'TransferTote' && item.firstActionTime <= Date.now() - (3 * 24 * 60 * 60 * 1000))
            )
        );
        setIncomingIOL(groupIOL(recordsWithMaxUpdatedTime, timeZone));
        setIOLLastRefreshTime(Date.now());
      }
    } else if (regionId === 1) {
      const latestSnapshotParams = {warehouse: {warehouseId: 'ALL', snapshotDate: 0}};
      const latestSnapshotData = await getPaginatedAPICall(latestSnapshotParams, getIOLSnapshot, "getIOLSnapshot");
      if (latestSnapshotData) {
        const snapshotDate = latestSnapshotData[0].record_updated_datetime;
        const snapshotParams = {warehouse: {warehouseId: warehouse, snapshotDate: snapshotDate}};
        const snapshotData = await getPaginatedAPICall(snapshotParams, getIOLSnapshot, "getIOLSnapshot");
        const liveParams = {warehouse: {warehouseId: warehouse, dwellTimeThreshold: Date.now(), snapshotTime: snapshotDate}};
        const liveData = await getPaginatedAPICall(liveParams, getIOL, "getIOL");
        if (snapshotData && liveData) {
          const filteredSnapshotData = snapshotData.filter(data =>
              liveData.filter(live => live.asin === data.asin && live.location_id === data.locationId).length <= 0
          );
          const combinedData = [...filteredSnapshotData, ...liveData];
          setIncomingIOL(groupIOL(combinedData, timeZone));
          setIOLLastRefreshTime(Date.now());
        }
      }
    } else {
      const params = {warehouse: {warehouseId: warehouse, dwellTimeThreshold: Date.now(), snapshotTime: 0}};
      const data = await getPaginatedAPICall(params, getIOL, "getIOL");

      if (data) {
        setIncomingIOL(groupIOL(data, timeZone));
        setIOLLastRefreshTime(Date.now());
      }
    }
  }, [setIncomingIOL, setIOLLastRefreshTime]);

  const getOCRVendorsData = useCallback(async (region) => {
    const params = {region: {regionId: region}};
    const data = await getPaginatedAPICall(params, getOCRVendors, "getOCRVendors");
    if (data) {
      setOcrVendors(data);
    }
  }, [setOcrVendors]);


  const getTSIForecastsData = useCallback(async (warehouse, timeZone) => {
    const params = {warehouse: {
        warehouseId: warehouse,
        minAppointmentTime: getStartOfDay(timeZone, -7),
        maxAppointmentTime: getStartOfDay(timeZone, 5)
      }};
    const data = await getPaginatedAPICall(params, getTSIForecasts, "getTSIForecasts");
    if (data) {
      setTSIForecasts(data);
    }
  }, [setTSIForecasts]);

  const getDCEscalations = useCallback(async (warehouse_id, region_id) => {
    const api = new MainAPI();
    const data = await api.getDCEscalations(warehouse_id, region_id)
    if (data) {
      setDCEscalations(data);
    }
  }, [setDCEscalations])

  const getScipForecastsData = useCallback(async (warehouse, timeZone) => {
    const latestParams = {nothing: {}};
    const latestSnapshotData = await getPaginatedAPICall(latestParams, getSCIPLatestForecast, "getSCIPLatestForecast");
    const latestSnapshotTime = latestSnapshotData ? latestSnapshotData[0].snapshot_time : 0;

    const params = {warehouse: {
        warehouseId: warehouse,
        minAppointmentTime: getStartOfDay(timeZone, 0),
        maxAppointmentTime: getStartOfDay(timeZone, 5),
        latestSnapShotTime: latestSnapshotTime
      }};
    const data = await getPaginatedAPICall(params, getSCIPForecasts, "getSCIPForecasts");
    if (data) {
      setScipForecasts(data);
    }
  }, [setScipForecasts]);

  const getFABForecastsData = useCallback(async (warehouse, timeZone) => {
    const params = {warehouse: {
        warehouseId: warehouse,
        minAppointmentTime: getStartOfDay(timeZone, -7),
        maxAppointmentTime: getStartOfDay(timeZone, 5)
      }};
    const data = await getPaginatedAPICall(params, getFABForecasts, "getFABForecasts");
    if (data) {
      setFabForecasts(data);
    }
  }, [setFabForecasts]);

  useEffect(() => {
    if (currentTab === "" && warehouseId !== "") {
      navigate(`/building/${warehouseId}/dockReceived`)
    }
  }, [currentTab, warehouseId, navigate]);

  useEffect(() => {
    setIncomingIncomingShipments();
    setIncomingDockReceived();
    getWarehouseShipmentSummaries(warehouseId, time_zone);
    setSearchResults();
    setSearchInput();
  }, [getWarehouseShipmentSummaries, warehouseId, time_zone, setIncomingDockReceived, setIncomingIncomingShipments]);

  useEffect(() => {
    const interval = setInterval(() => {
      getWarehouseShipmentSummaries(warehouseId, time_zone);
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, [getWarehouseShipmentSummaries, warehouseId, time_zone]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (tabActive) {
        setNotifications(countNotifications(incomingDockReceived))
      }
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [incomingDockReceived, tabActive]);

  useEffect(() => {
    setIncomingIOL();
    getIOLData(warehouseId, time_zone, wms, region_id);
  }, [setIncomingIOL, warehouseId, getIOLData, time_zone, wms, region_id]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (tabActive) {
        getIOLData(warehouseId, time_zone, wms, region_id);
      }
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, [getIOLData, warehouseId, time_zone, tabActive, wms, region_id]);

  useEffect(() => {
    const interval = setInterval(() => {
      getInstockData(warehouseId, time_zone);
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, [getInstockData, warehouseId, time_zone]);

  useEffect(() => {
    const interval = setInterval(() => {
      getInstockVbi(warehouseId, time_zone);
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, [getInstockVbi, warehouseId, time_zone]);

  useEffect(() => {
    setOcrVendors();
    getOCRVendorsData(region_id);
  }, [setOcrVendors, region_id, getOCRVendorsData]);

  useEffect(() => {
    setInstockData();
    setSnapshotLocalInstock();
    getInstockData(warehouseId, time_zone);
  }, [setInstockData, setSnapshotLocalInstock, warehouseId, getInstockData, time_zone]);

  useEffect(() => {
    setInstockVbi();
    setLatestVbi();
    setVbiDate();
    getInstockVbi(warehouseId, time_zone);
  }, [setInstockVbi, setLatestVbi, setVbiDate, warehouseId, getInstockVbi, time_zone]);

  useEffect(() => {
    const interval = setInterval(() => {
      getOCRVendorsData(region_id);
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, [region_id, getOCRVendorsData]);


  useEffect(() => {
    setScipForecasts();
    getScipForecastsData(warehouseId, time_zone);
  }, [setScipForecasts, warehouseId, time_zone, getScipForecastsData]);

  useEffect(() => {
    const interval = setInterval(() => {
      getScipForecastsData(warehouseId, time_zone);
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, [warehouseId, time_zone, getScipForecastsData]);

  useEffect(() => {
    setTSIForecasts();
    getTSIForecastsData(warehouseId, time_zone);
  }, [setTSIForecasts, warehouseId, time_zone, getTSIForecastsData]);

  useEffect(() => {
    const interval = setInterval(() => {
      getTSIForecastsData(warehouseId, time_zone);
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, [warehouseId, time_zone, getTSIForecastsData]);

  useEffect(() => {
    setDCEscalations();
    getDCEscalations(warehouseId, region_id);
  }, [setDCEscalations, warehouseId, region_id, getDCEscalations]);

  useEffect(() => {
    const interval = setInterval(() => {
      getDCEscalations(warehouseId, region_id);
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, [warehouseId, region_id, getDCEscalations]);

  useEffect(() => {
    setFabForecasts();
    getFABForecastsData(warehouseId, time_zone);
  }, [setFabForecasts, warehouseId, time_zone, getFABForecastsData]);

  useEffect(() => {
    const interval = setInterval(() => {
      getFABForecastsData(warehouseId, time_zone);
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, [warehouseId, time_zone, getFABForecastsData]);

  useEffect(() => {
        if (shipmentSummaries && scipForecasts && tsiForecasts && ocrVendors && region_id) {
          const {
            parsedIncomingShipments,
            parsedDockReceived,
            parsedDockReceiveSimple
          } = parseShipments(shipmentSummaries, ocrVendors, time_zone, scipForecasts, tsiForecasts, region_id);
          setLastRefreshTime(Date.now());
          setNotifications(countNotifications(parsedDockReceived));
          setIncomingIncomingShipments(parsedIncomingShipments);
          setIncomingDockReceived(parsedDockReceived);
          setCurrentNyr(calculateCurrentNYR(parsedDockReceiveSimple));
        }
      }, [shipmentSummaries, setLastRefreshTime, setNotifications, setIncomingIncomingShipments, setIncomingDockReceived,
        setCurrentNyr, ocrVendors, time_zone, scipForecasts, tsiForecasts, region_id, fabForecasts]
  );

  useEffect(() => {
    if ((Date.now() - lostFocusTimestamp) > (5 * 60 * 1000)) {
      getWarehouseShipmentSummaries(warehouseId, time_zone);
      setNotifications(countNotifications(incomingDockReceived));
      getIOLData(warehouseId, time_zone, wms, region_id);
    }
    if (!tabActive) {
      setLostFocusTimestamp(Date.now())
    }

  }, [tabActive, lostFocusTimestamp, setLostFocusTimestamp, warehouseId, time_zone, incomingDockReceived, getWarehouseShipmentSummaries, getIOLData, wms, region_id])

  useEffect(() => {
    if (shipmentSummaries && instockData && incomingDockReceived){
      const updatedInstock = [];
      const idTimeMap = new Map(incomingDockReceived.map(obj => [`${obj.orderId}`, [obj.firstDockReceiveTime, obj.suppression]]));
      instockData.forEach(obj => {

        if (idTimeMap.has(obj.transId)) {
          const array = idTimeMap.get(obj.transId);
          if (obj.details.length>0) {
            obj.firstDockReceiveTime = array[0];
            updatedInstock.push(obj)
          }
        }
      });
      updatedInstock.sort((a, b) => b.vbi - a.vbi);
      setUpdatedInstock(updatedInstock);

      const sumOfQty = updatedInstock.reduce((total, obj) => total + obj.oos_quantity, 0);
      const sumOfQty1 = updatedInstock.reduce((total, obj) => total + obj.oos_risk_quantity, 0);
      const sumOfQty2 = updatedInstock.reduce((total, obj) => total + obj.vbi, 0);
      const sumNoos = updatedInstock.reduce((total, obj) => total + obj.noos, 0);
      setOosAsinCount(sumOfQty);
      setOosRiskAsinCount(sumOfQty1);
      setVbiCtm(sumOfQty2);
      setNoosCount(sumNoos);
    }
  }, [warehouseId, time_zone, incomingDockReceived, instockData, shipmentSummaries, setUpdatedInstock, setOosAsinCount, setOosRiskAsinCount, setVbiCtm, setNoosCount])

  useEffect(() => {
    if(latestVbi && vbiCtm){
      (document.title = `VBI: ${latestVbi}% OOS CTM: ${Math.round(vbiCtm)} bps`);
    }
    else if (latestVbi){
      (document.title = `VBI: ${latestVbi}% IRIS Marauder's Map`)
    }
    else{
      document.title = "IRIS Marauder's Map"
    }
  }, [latestVbi, vbiCtm]);

  useEffect(() => {
    const interval = setInterval(() => {
      if(latestVbi && vbiCtm){
        (document.title = `VBI: ${latestVbi}% OOS CTM: ${Math.round(vbiCtm)} bps`);
      }
      else if (latestVbi){
        (document.title = `VBI: ${latestVbi}% IRIS Marauder's Map`)
      }
      else{
        document.title = "IRIS Marauder's Map"
      }
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, [latestVbi, vbiCtm]);

  const getSearchData = useCallback(async () => {
    setSearchResults([]);
    setSearchLoading(true);
    // Search pattern recognition logic
    let searches = [];
    // Just in case it's undefined/null
    if (!searchInput || searchInput === "") {
      setSearchResults(undefined);
      setSearchLoading(false);
      return;
    }
    // Making it easier to swap search params down the road
    let palletSearch = false;
    let query = getShipmentSearchItems;
    let queryName = "getShipmentSearchItems";
    // ASIN: https://w.amazon.com/bin/view/ASIN/
    if (searchInput.length === 10 && (searchInput.startsWith('B0') || searchInput.startsWith('X0'))) {
      searches.push(searchInput);

      // Barcode is numeric
      // 12 = UPC, 13 = EAN/ISBN
      // Listen closely to feedback from users. This may be too narrow of a filter.
    } else if (Number(searchInput) && (searchInput.length >= 6 && searchInput.length <= 14)) {
      const barcodeParams = {
        barcodeId: {
          barcode: searchInput,
          marketplace_id: marketplace_id,
        }
      }
      const barcodeData = await getPaginatedAPICall(barcodeParams, getAsinsFromBarcode, "getAsinsFromBarcode");
      if (barcodeData.length > 0) {
        const asins = barcodeData.map(item => item.asin);
        searches.push(...asins);
      } else {
        const api = new MainAPI()
        await api
            .barcodeSearch(warehouseId, searchInput)
            .then((response) => {
              for (const asin of response.asins) {
                searches.push(asin);
              }
            })
      }
    } else if (['paX', 'PALLET'].filter((prefix) => searchInput.startsWith(prefix)).length > 0) {
      palletSearch = true;
      searches.push(searchInput);

      //   Key word search
    } else {

    }
    let searchData = [];
    for (const search of searches) {
      let params = {};
      if (palletSearch) {
        params = {
          palletId: {
            palletId: search
          }
        };
        query = getPalletId;
        queryName = "getPalletId";
      } else {
        params = {
          warehouseId: {
            warehouseId: warehouseId,
            inputId: search,
            minAppointmentTime: getStartOfDay(time_zone, shipmentSummariesAPIRange.min),
            maxAppointmentTime: getStartOfDay(time_zone, 4)
          }
        };
      }

      const data = await getPaginatedAPICall(params, query, queryName);
      if (data) {
        searchData = [...searchData, ...data];
      }
    }

    if (searchData) {
      const uniqueOrderIds = [...new Set(searchData.map(item => item.orderId))]
      const shipmentSummaries = await Promise.all(
          uniqueOrderIds.map(async (orderId) => {
            const shipmentType = orderId.split('_')[0];
            const summaryParams = {
              orderId: orderId,
              recordType: orderId.replace(shipmentType, `${shipmentType}Summary`)
            };
            const summaryData = await API.graphql({ query: getSingleShipmentSummary, variables: summaryParams });
            if (summaryData.data) {
              return JSON.parse(JSON.stringify(summaryData.data.getSingleShipmentSummary));
            }
            return null
          })
      );

      const groupedShipments = groupSearchedShipments(searchData, shipmentSummaries)
      setSearchResults(groupedShipments);
    } else {
      setSearchResults([]);
    }
    setSearchLoading(false);
  }, [searchInput, warehouseId, time_zone, marketplace_id]);

  const DockReceivedTab = useMemo(() =>
          <DockReceived
              warehouseId={warehouseId}
              incomingDockReceived={incomingDockReceived}
              notifications={notifications}
              lastRefreshTime={lastRefreshTime}
              dockFullness={currentNYR}
              dcEscalations={dcEscalations}
              getDCEscalations={getDCEscalations}
          />,
      [warehouseId, incomingDockReceived, notifications, lastRefreshTime, currentNYR, dcEscalations, getDCEscalations]
  );

  const IncomingShipmentsTab = useMemo(() =>
          <IncomingShipments
              warehouseId={warehouseId}
              incomingIncomingShipments={incomingIncomingShipments}
              lastRefreshTime={lastRefreshTime}
          />,
      [warehouseId, incomingIncomingShipments, lastRefreshTime]
  );

  const IOLTab = useMemo(() =>
          <IOL warehouseId={warehouseId} incomingIOL={incomingIOL} iolLastRefreshTime={iolLastRefreshTime}/>,
      [incomingIOL, iolLastRefreshTime, warehouseId]
  );

  const InstockTab = useMemo(() =>
          <Instock warehouseId={warehouseId} instock={updatedInstock} instockSnapshot={snapshotLocalInstock} vbi={instockVbi} vbiDate={vbiDate} latestVbi={latestVbi} oosAsinCount={oosAsinCount} oosRiskAsinCount={oosRiskAsinCount} vbiCtm = {vbiCtm} noosCount={noosCount}/>,
      [warehouseId, updatedInstock, instockVbi, vbiDate, latestVbi, snapshotLocalInstock, oosAsinCount, oosRiskAsinCount, vbiCtm, noosCount]
  );

  const ShipmentSearchTab = useMemo(() =>
          <ShipmentSearch
              searchResults={searchResults}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              getSearchData={getSearchData}
              setSearchResults={setSearchResults}
              searchLoading={searchLoading}
          />,
      [searchResults, searchInput, setSearchInput, getSearchData, setSearchResults, searchLoading]
  );

  // This is make the router dynamically render the tab otherwise nothing changes when the navigate function is used
  useEffect(() => {

        // const newWarehouseID = params.warehouseId ? params.warehouseId : "";
        const newTab = params.selectedTab ? params.selectedTab : "";
        setCurrentTab(newTab);
        if (newTab === "incomingShipments") {
          setRenderedTab(IncomingShipmentsTab);
        }
        else if (newTab === "dockReceived") {
          setRenderedTab(DockReceivedTab);
        }
        else if (newTab === "iol") {
          setRenderedTab(IOLTab);
        }
        else if (newTab === "instock") {
          setRenderedTab(InstockTab);
        }
        else if (newTab === "shipmentSearch") {
          setRenderedTab(ShipmentSearchTab)
        }
        else {
          setRenderedTab(<></>)
        }

      }, [DockReceivedTab, IncomingShipmentsTab, IOLTab, InstockTab, ShipmentSearchTab, params.selectedTab]
  )


  return (
      <>
        <Column>
          <TabGroup
              value={currentTab}
              onChange={(value) => {
                navigate(`/building/${warehouseId}/${value}`)
                setCurrentTab(value);
              }}
              type="basic"
          >
            <Tab value="dockReceived">Dock Received {
                (notifications.pastSLA.length + notifications.nearSLA.length) > 0
                && <Badge value={notifications.pastSLA.length + notifications.nearSLA.length} type="error" />
            }
            </Tab>
            <Tab value="incomingShipments">Incoming Shipments</Tab>
            <Tab value="iol">IOL</Tab>
            {latestVbi && !isNaN(latestVbi) && <Tab value="instock">
              {latestVbi && !isNaN(latestVbi) && (
                  <Tag type={latestVbi >= 90 ? "success" : (latestVbi >= 85 ? "warning" : "error")}>{`VBI: ${latestVbi}% OOS CTM: ${Math.round(vbiCtm)} bps`}</Tag>
              )}

            </Tab>}
            <Tab value={"shipmentSearch"}>Shipment Search</Tab>
          </TabGroup>
        </Column>
        <Column>

          {renderedTab}
        </Column>

      </>
  )
}

BuildingTabs.propTypes = {
  warehouseId: PropTypes.string.isRequired,
}

export default BuildingTabs;
